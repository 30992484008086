export default {
  banner: {
    text: '«Ai Soul» is a place of empowerment, inspiration, and attentiveness',
    button: 'Get in Touch!'
  },
  information: [
    {
      description: `Routined dedication to your self care ensures healthy energetic flow, blood-circulation, rejuvenation of your strength and positively boosts your self-esteem. `,
      style: 'title is-size-5 is-size-6-mobile pb-3',
    },
    {
      description: `Simple procedure of deep cleansing pores or lymphatic drainage massage with our body contouring device detoxes your body and removes all the waste leaving you refreshed and anew. It is important to fully accept that you deserve every minute dedicated to your self-care  and fully give in and relax in the process. This mindset helps you generate the most authentic love towards your body and thank it for everything it does for your functioning.`,
      style: 'is-size-5 is-size-6-mobile',
    },
  ],

  images: [
    { imagePath: '../../gallery/img_1.jpg' },
    { imagePath: '../../gallery/img_2.jpg' },
    { imagePath: '../../gallery/img_3.jpg' },
    { imagePath: '../../gallery/img_4.jpg' },
    { imagePath: '../../gallery/img_5.jpg' },
    { imagePath: '../../gallery/img_6.jpg' },
    { imagePath: '../../gallery/img_7.jpg' },
  ],
};
