import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

interface banner {
  text: string;
  button: string;
}

const HomeBanner: FC<banner> = ({ text, button }) => {
  return (
    <section className="hero is-large has-bg-img">
      <div className="hero-body">
        <div className="level">
          <div className="level-left">
            <section className="section">
              <p className="block is-size-3">{text}</p>
              <button className="button">
                <Link
                  style={{ textDecoration: 'none', color: 'black' }}
                  to="contact"
                >
                  {button}
                </Link>
              </button>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

HomeBanner.propTypes = {
  text: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,
};

export default HomeBanner;
