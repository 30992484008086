import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface props {
  imagePath: string;
}

const HomeImage: FC<props> = ({ imagePath }) => {
  return (
    <div className="card">
      <div className="card-image">
        <figure className="image is-3by2">
          <img src={imagePath} alt="image" />
        </figure>
      </div>
    </div>
  );
};

HomeImage.propTypes = {
  imagePath: PropTypes.string.isRequired,
};

export default HomeImage;
