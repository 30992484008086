import React, { FC } from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import '../styles/home.scss';

import HomeBanner from '../components/homeBanner';
import home from '../data/home';
import HomeImage from '../components/homeImage';

const Home: FC = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <HomeBanner text={home.banner.text} button={home.banner.button} />

      <div className="container">
        <div className="box my-3">
          <div className="section">
            <HomeImage imagePath="../../front.jpg" />
          </div>
          <div className="section">
            {home.information.map((info, idx) => (
              <h2 className={info.style} key={idx}>
                {info.description}
              </h2>
            ))}
          </div>
        </div>
        <div className="columns is-multiline is-mobile is-centered">
          {home.images.map(
            (image, idx) =>
              typeof window !== 'undefined' && (
                <div
                  key={idx}
                  className={`column is-half-tablet is-one-quarter-desktop ${
                    window.innerWidth <= 800 ? 'is-full' : ''
                  }`}
                >
                  <HomeImage imagePath={image.imagePath} />
                </div>
              ),
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Home;
